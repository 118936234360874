.prod-page-wrap {
    padding-top: rem(32);
    padding-bottom: rem(120);
    padding-left: rem(38);
    padding-right: rem(38);
    // 1632 + 38 * 2 = 1708
    // 1504 + 38 * 2 = 1580
    max-width: rem(1580);
    margin-left: auto;
    margin-right: auto;
    font-size: rem(18);
    font-weight: 300;
    @media screen and (max-width: 360px) {
        padding-right: rem(24);
        padding-left: rem(24);
    }
}

.breadcrumbs {
    margin: 0 0 rem(24);
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    // .breadcrumbs__item
    &__item {
        position: relative;
        font-size: rem(18);
        color: #050505;

        &:after {
            content: "/";
            display: inline-block;
            margin-left: rem(4);
            margin-right: rem(4);
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
        // .breadcrumbs__item_home
        &_home {
            .breadcrumbs__link {
                color: $primary;
            }
        }

        &:not(.breadcrumbs__item_home) {
            .breadcrumbs__link:hover {
                color: #707070;
            }
        }
    }
    // .breadcrumbs__link
    &__link {
        display: inline-block;
        text-decoration: none;
        color: inherit;
    }
}

.prod-main {
    background-color: $white;
    margin-bottom: rem(48);
    padding: rem(240) rem(24) rem(32);
    padding-left: clamp(rem(24), 5.4vw, rem(104));
    padding-right: clamp(rem(24), 5.4vw, rem(104));
    @media screen {
        padding-top: 36px;
        padding-bottom: 48px;
    }
        // .prod-main__inner
        &__inner {
            max-width: rem(1240);
            display: flex;
            gap: rem(64);
            gap: clamp(rem(32), 3.34vw, rem(64));
            flex-direction: column;
            @media screen and (min-width: 768px) {
                flex-direction: row;
            }
        }
		// .prod-main__info
		&__info {
            flex-grow: 1;
            flex-shrink: 1;

            display: flex;
            flex-direction: column;
		}
}
.prod-images {
    flex-basis: 260px;
    // max-width: 260px;
    // width: 260px;
    width: 100%;
    max-width: 350px;
    flex-grow: 0;
    flex-shrink: 1;
    height: auto;

    @media screen and (min-width: 768px) {
        max-width: 260px;
    }

    @media screen and (min-width: 1024px) {
        flex-basis: 337px;
        max-width: 337px;
        // width: 337px;
        
    }

    // .owl-stage {
    //     display: -webkit-box;
    //     display: -moz-box;
    //     display: -ms-box;
    //     display: box;
    // }
    // .owl-item {
    //     display: inline-block;
    // }
}

.prod-main-img {
    width: 100%;
    // width: inherit;
    max-width: 100%;
    margin-bottom: rem(8);
    box-shadow: 0 2px 7px rgba(0,0,0,0.19);
}

.prod-mini-images-wrap {
    margin-left: -6px;
    margin-right: -6px;
}
.prod-mini-images {
    // display: flex;
    // gap: rem(12);
    
    .owl-thumb-item {
        cursor: pointer;
        flex-shrink: 1;
        flex-grow: 0;
        padding: 8px 6px;
        

        img {
            display: block;
            max-width: 100%;
            box-shadow: 0 3px 7px rgba(0,0,0,0.19);
        }
    }

    .owl-item.linked .owl-thumb-item img {
        // outline: 1px solid #A0CDCD;
        // outline: 1px solid $border-color;
        outline: 1px solid #D5D5D5;
    }
}
.prod-name {
    color: #050505;
    font-size: rem(22);
    font-weight: 400;
}
.prod-rating {
    display: flex;
    flex-wrap: wrap;
    gap: rem(8);
    font-size: 1rem;
		// .prod-rating__stars
		&__stars {
            color: $warning;
		}
		// .prod-rating__text
		&__text {
            font-weight: 300;
            opacity: 0.64;
		}
}

.prod-short-desc {
		// .prod-short-desc__header
		&__header {
            padding-bottom: rem(12);
            border-bottom: 1px solid $border-color;
		}
		// .prod-short-desc__body
		&__body {
            padding-top: rem(12);
		}
}
.prod-subtitle {
    font-weight: 400;
    color: #050505;
    margin: 0;
		// .prod-subtitle_accent
		&_accent {
            color: $primary;
            font-weight: 600;
		}
}
.prod-price {
    font-size: rem(22);
    color: #050505;
    font-weight: 400;
    span {
        color: $primary;
        font-weight: 600;
    }
}

.prod-colors {
    display: flex;
    gap: rem(6);
    min-width: rem(68);
}
.prod-color {
    display: block;
    width: rem(24);
    height: rem(24);
    border-radius: 100%;
    border: 1px solid $white;
    box-shadow: 0 2px 6px rgba(0,0,0,0.19);
    // transition: all 0.15s ease-out;

    &.prod-color_selected {
        // outline: 2px solid $primary-light;
        // outline: 2px solid $border-color;
        // outline: 1px solid $primary;
        outline: 1px solid #a8a8a8;
        // outline: 1px solid $border-color;
        transform: scale(1.15);
    }

    &:first-child {
        margin-left: auto;
    }
}
.prod-actions {
    display: flex;
    flex-wrap: wrap;
    gap: rem(24);
    margin-top: auto;
}
.prod-quantity {
    display: flex;
    align-items: center;
    gap: rem(12);
		// .prod-quantity__text
		&__text {
		}
		// .prod-quantity__group
		&__group {
            input.form-control {
                max-width: 54px;
                min-width: 46px;
                border: none;
                font-weight: bold;
                text-align: center;
                // font-size: rem(18);

                // @media screen and (min-width: 1024px) {
                //     font-size: rem(22);
                // }
            }

            .input-group {
                flex-wrap: nowrap;
            }

            .btn {
                border-color: rgba(#707070, 0.3)!important;
                font-size: rem(18);
                line-height: 1.2;
                // min-width: 36px;
                min-width: 32px;
            }
		}
}

.btn-add-to-cart {
    display: flex;
    align-items: center;

    &:disabled {
        background-color: #9F9F9F;
        border-color: #9F9F9F;
    }
    // .btn-add-to-cart__icon
    &__icon {
        display: inline-block;
        font-size: rem(11);
        margin-right: rem(6);
        // margin-top: 2px;
    }
}

.prod-spec {
    background-color: $white;
    margin-bottom: rem(48);
    padding: rem(24) rem(44);
    padding-left: clamp(rem(24), 3vw, rem(44));
		// .prod-spec__header
		&__header {
            padding-bottom: rem(12);
            border-bottom: 1px solid $border-color;
		}
		// .prod-spec__title
		&__title {
            margin: 0;
            font-weight: 400;
		}
		// .prod-spec__body
		&__body {
            padding: rem(12) 0;
		}
		// .prod-spec__group
		&__group {
		}
}
.prod-spec-link {
    display: flex;
    flex-wrap: wrap;
		// .prod-spec-link__label
		&__label {
            color: $primary;
            margin-right: 0.5rem;
		}
		// .prod-spec-link__link
		&__link {
            color: $body-color;
            &:hover {
                color: #707070;
            }
		}
}
.shop-title {
    margin-bottom: rem(32);
    font-size: clamp(rem(20), calc(2.2vw + 0px), rem(28));
    line-height: 1.2;
    font-weight: 600;
    color: #474848;
}

.prod-inputs-wrap {
    max-width: rem(320);
}
