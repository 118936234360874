#btnRegister {
    &:disabled {
        background: #4cc2c2;
        cursor: not-allowed;
    }
}


.l-wrap {
    min-height: 100vh;
    background-color: $primary;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: rem(24);
}
.l-box {
    background-color: $white;
    border-radius: 1rem;
    padding: rem(32) rem(24);
    width: 100%;
    max-width: rem(510);
    @media screen and (min-width: 576px) {
        padding: rem(52) rem(48);
    }

		// .l-box__logo
		&__logo {
                  display: block;
                  max-width: rem(185);
                  margin: 0 auto rem(16);

                  img {
                        display: block;
                        max-width: 100%;
                  }
		}

		// .l-box__title
		&__title {
                  // margin-bottom: em(30, 35);
                  line-height: 1.2;
                  font-size: rem(35);
                  font-weight: 600;
                  text-align: center;
                  margin-bottom: rem(10);
		}

            // .l-box__desc
            &__desc {
                  font-size: rem(19);
                  text-align: center;
            }

		// .l-box__form
		&__form {
                  padding-top: rem(19);
		}

		// .l-box__signup
		&__signup {
                  margin-top: rem(24);
                  font-size: rem(19);
                  line-height: 1.2;
                  text-align: center;
            a {
                text-decoration: none;
            }
		}
}
.l-box-form {

		// .l-box-form__group
		&__group {
            margin-bottom: rem(12);
		}

		// .l-box-form__fpassword
		&__fpassword {
            margin-top: rem(14);
            margin-bottom: rem(20);
		}

            // .l-box-form__btn
            &__btn {
                  border: none;
                  border-radius: 10px;
                  display: block;
                  width: 100%;
                  background-color: $primary;
                  text-align: center;
                  color: $white;
                  line-height: 1.2;
                  font-size: rem(19);
                  padding: rem(21) rem(16);
                  margin-top: rem(20);
            }
}
.l-box-input {
    display: flex;
    border: 1px solid $border-color;
    border-radius: 10px;

    &.has-error {
      border-color: rgba($red, 0.49);
    }
		// .l-box-input__icon
		&__icon {
            flex-grow: 0;
            flex-shrink: 0;
            color: #c8c8c8;
            padding: rem(14);
            width: rem(48);
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: inherit;

            border-radius: 10px 0 0 10px;

            @media screen and (min-width: 576px) {
                  padding: rem(22);
                  width: rem(64);
                  height: rem(64);
            }

            svg {
                display: block;
                max-width: 100%;
                height: auto;
            }
		}

		// .l-box-input__field
		&__field {
                  flex-grow: 1;
                  flex-shrink: 1;
                  border: none;
                  border-radius: 0 10px 10px 0;
                  padding: rem(16) rem(19);
                  display: block;
                  max-width: 100%;
                  flex-basis: 0;
                  box-sizing: border-box;
                  min-width: 0;

                  &:focus {
                        outline-color: $primary;

                        .l-box-input.has-error & {
                              outline-color: $red;
                        }
                  }

                  &_phone {
                        width: 100%;
                        border-radius: 10px;
                        padding-left: rem(58)!important;
                        @media screen and (min-width: 576px) {
                              padding-left: rem(74)!important;
                        }
                  }
		}
      .iti.iti--allow-dropdown {
            border-radius: inherit;
            width: 100%;
      }
      .iti__flag-container {
            // padding: rem(14);
            width: rem(48);
            border-radius: 10px 0 0 10px;
            max-height: 100%;

            .iti__selected-flag {
                  justify-content: center;
                  border-radius: 10px 0 0 10px;
                  overflow: hidden;
                  border-right: 1px solid $border-color;
            }
            
            @media screen and (min-width: 576px) {
                  // padding: rem(22);
                  width: rem(64);
                  height: rem(64);
            }
      }
}
.fpassword {
    @media screen and (min-width: 576px) {
        display: flex;
        justify-content: space-between;
    }
		// .fpassword__link
		&__link {
            text-decoration: none;
		}
}

.l-box-form-checkbox {
      display: flex;

      // .l-box-form-checkbox__input
      &__input {
            accent-color: $primary;
            width: 20px;
            height: 20px;
            border-radius: 0;
            border-color: $border-color;
      }

      // .l-box-form-checkbox__label
      &__label {
            display: block;
            font-size: rem(19);
            margin-left: rem(8);
            margin-top: -3px;
            // .l-box-form-checkbox__label_muted
            &_muted {
                  opacity: 0.59;
                  font-size: 1rem;
                  margin-top: 0;
            }
      }
}


.l-box-form-error-msg {
      font-size: rem(18);
      color: $red;
}

.js-toggte-pwd-visibility {
      cursor: pointer;

      .icon-hide-pwd {
            display: none;
      }
}
