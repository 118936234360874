.catalog-wrap {
    padding-top: rem(64);
    padding-bottom: rem(120);
    padding-left: rem(38);
    padding-right: rem(38);
    // 1632 + 38 * 2 = 1708
    // 1504 + 38 * 2 = 1580
    max-width: rem(1580);
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 360px) {
        padding-right: rem(24);
        padding-left: rem(24);
    }
}

.catalog-top {
    margin-bottom: rem(32);

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @media screen and (min-width: 992px) {
        margin-bottom: rem(45);
    }
}

.catalog-show {
    display: flex;
    align-items: center;
    // .catalog-show__text
    &__text {
        font-size: 1rem;
        opacity: 0.75;
        margin-right: rem(12);
    }
    // .catalog-show__select
    &__select {
        width: 50px;
    }
}
.js-products-show-number {
}


.catalog-columns {
    
    --bs-gutter-y: #{rem(54)};

    @media screen and (min-width: 1024px) {
        --bs-gutter-x: #{rem(56)};
    }
}

.catalog-grid {
    --bs-gutter-y: 1.5rem;
    --bs-gutter-x: 1.5rem;

    @media screen and (min-width: 1200px) {
        --bs-gutter-y: #{rem(42)};
        --bs-gutter-x: #{rem(35)};
    }
    
}

.catalog-sidebar {
    flex-basis: 280px;
    flex-grow: 0;
    flex-shrink: 1;

    @media screen and (max-width: 991.98px) {
        flex-basis: 0;
        width: 0;
        padding: 0!important;
        position: absolute;
    }

    @media screen and (min-width: 1200px) {
        flex-basis: 340px;
    }
}

.catalog-main {
    flex-grow: 1;
    flex-shrink: 1;
}

