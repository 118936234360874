.site-header {
    position: relative;
    padding: rem(20) rem(38) rem(20);
    @media screen and (max-width: 360px) {
        padding-right: rem(24);
        padding-left: rem(24);
    }
    // .site-header__container
    &__container {
        display: grid;
        grid-template-columns: rem(50) auto rem(50);
        gap: rem(16);
        align-items: center;
        max-width: rem(1632);
        // max-width: rem(1504);
        margin-left: auto;
        margin-right: auto;

        @media screen and (min-width: 992px) {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            column-gap: rem(16);
        }
        @media screen and (min-width: 992px) {
            column-gap: rem(32);
        }
    }

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-color: $white;
        z-index: 2;

        background-color: inherit;
    }

    // .site-header_bg-light
    &_bg-light {
        &:after {
            background-color: var(--bs-body-bg);
        }
    }
}

.header-menu-icon {
    z-index: 100;

    @media screen and (min-width: 992px) {
        display: none;
    }

    svg {
        path {
            transform-origin: center;
            transition: all 0.15s ease-out;
            &:first-child {
                transform: translate(0, 6px) rotate(0);
            }
            &:nth-child(2) {
                opacity: 1;
            }
            &:last-child {
                transform: translate(0, -6px) rotate(0);
            }
        }
    }

    &.opened {
        svg {
            path {
                &:first-child {
                    transform: translate(0) rotate(-45deg);
                }
                &:nth-child(2) {
                    opacity: 0;
                }
                &:last-child {
                    transform: translate(0) rotate(45deg);
                }
            }
        }
    }
}

.header-logo {
    display: inline-block;
    z-index: 100;
    max-width: rem(276);
    margin-left: auto;
    margin-right: auto;
    img {
        display: block;
        max-width: 100%;
    }

    @media screen and (min-width: 992px) {
        margin: 0 auto 0 0;
    }
}
.header-nav-wrap {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background: $white;
    z-index: 99;
    padding: rem(116) rem(38) rem(32);
    overflow-y: auto;
    @media screen and (max-width: 360px) {
        padding-right: rem(24);
        padding-left: rem(24);
    }

    @media screen and (min-width: 992px) {
        display: contents!important;
        position: static;
    }
    
}
.header-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 3;

    @media screen and (min-width: 992px) {
        display: flex;
        column-gap: rem(16);
    }
    @media screen and (min-width: 992px) {
        column-gap: rem(32);
    }

    li {
        font-weight: 400;
        font-size: rem(22);
        line-height: 1.59;
        margin-bottom: rem(16);
        @media screen and (min-width: 992px) {
            font-size: rem(20);
        }
        a {
            display: block;
            text-decoration: none;
            transition: all 0.15s ease-out;
            &:hover {
                color: $primary;
            }
        }

        @media screen and (min-width: 992px) {
            margin-bottom: 0;
        }

        &.has-subnav {
            position: relative;
            > a {
                display: flex;
                gap: 6px;
                align-items: center;
                justify-content: space-between;
                &:after {
                    content: "\e90d";
                    font-family: 'icomoon' !important;
                    speak: never;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    font-size: rem(18);
                    /* Better Font Rendering =========== */
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            }
            
            &:hover {
                .header-subnav {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

.header-subnav {
    list-style: none;
    margin: 0;
    padding: 0;
    padding: rem(16) rem(16) 0;
    // display: none;
    @media screen and (min-width: 992px) {
        display: block;
        background-color: $white;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(100%);
        z-index: 10;
        padding-bottom: rem(16);
        width: max-content;
        max-width: rem(250);

        transition: all 0.3s ease-out;

        opacity: 0;
        visibility: hidden;
        
        li {
            font-size: 0.85em;
            margin-bottom: rem(12);
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.header-links {
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 100;
    display: flex;
    justify-content: flex-end;
    column-gap: rem(16);
    @media screen and (min-width: 992px) {
        column-gap: rem(32);
    }

    li {
        text-align: right;
        font-weight: 400;
        font-size: rem(22);
        line-height: 1.59;
        @media screen and (min-width: 992px) {
            font-size: rem(20);
        }
        &.hide-desktop {
            display: none;
        }
        @media screen and (max-width: 991.98px) {
            &:not(.hide-desktop) {
                display: none;
            }
    
            &.hide-desktop {
                display: block;
            }
        }

        a {
            text-decoration: none;
            transition: all 0.15s ease-out;

            &:hover {
                color: $primary;
            }

            

            .c-icon {
                font-size: rem(19);
                color: $primary;
            }
            // display: block;
        }

        &.header-cart {
            display: block!important;
            a {
                color: $primary;
                margin-left: 1rem;
                font-size: rem(20);
            }
            
        }
    }
    
}

