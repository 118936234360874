html {
    font-size: 18px;
}

body.locked {
    overflow: hidden;
}
body {
    font-weight: 300;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
}

// img {
//     display: block;
//     max-width: 100%;
// }

b, strong {
    font-weight: 600;
}

.fw-semibold {
    font-weight: 600!important;
}

.site-page-content {
    font-weight: 300;
    line-height: 1.6;
    font-size: rem(18);
    font-size: clamp(rem(18), calc(1.5vw + 0px), rem(22));

    p {
        margin-bottom: 1rem;
    }
    
    // @media screen and (min-width: 1200px) {
    //     font-size: rem(22);
    // }
}

.secton-py {
    padding-top: rem(60);
    padding-bottom: rem(60);
}

@media screen and (min-width: 768px) {
    .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;
    }
}

.s-title-underline {
    font-size: rem(30);
    line-height: 1.16;
    font-weight: 400;
    color: $primary;
    margin-bottom: em(34, 30);

    span {
        display: inline-block;
        position: relative;
        padding-bottom: rem(10);
        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #E73034;
        }
    }

    // .s-title-underline_center
    &_center {
        text-align: center;
    }
}

.body_bg_light {
    background-color: #f0f0f4;
}

.gy-d {
    --bs-gutter-y: 2rem;
}

.search-box {
    position: relative;
    width: 100%;
    
    @media screen and (min-width: 576px) {
        max-width: rem(248);
    }
    @media screen and (min-width: 1200px) {
        max-width: rem(284);
    }
    // .search-box__input
    &__input {
        padding-right: rem(32);
    }
    // .search-box__icon
    &__icon {
        font-size: rem(16);
        color: $black;
        opacity: 0.28;
        position: absolute;
        right: rem(12);
        top: 50%;
        transform: translateY(-50%);
    }
}


// Form elements fix
.form-check.form-check_lg {
    padding-left: rem(30);
    .form-check-input {
        width: rem(20);
        height: rem(20);
        border-radius: 0;
        margin-left: rem(-30);
    }

    .form-check-input[type="checkbox"] {
        border-radius: 0;
    }

    .form-check-label {
        padding-top: rem(2);
    }
}

.form-check {
    .form-check-input[type="checkbox"] {
        border-radius: 0;
    }
    .form-check-label {
        font-size: 1rem;
        display: inline-block;
        line-height: 1.2;
        // transform: translateY(-3px);
    }
}

.select2-container--default .select2-selection--single {
    border-radius: 0;
}

.select2-dropdown {
    border-radius: 0;
}

.select2-selection__placeholder, .select2-container .select2-selection--single .select2-selection__placeholder {
    color: #74788d;
    font-size: 1rem;
}

.form-control {
    font-size: 1rem;
}
.form-control::placeholder {
    font-size: inherit;
}

.btn.btn_wide {
    width: 190px;
    max-width: 100%;
}

.custom-file-input {
    cursor: pointer;
    display: flex;
    align-items: center;

    input[type="file"] {
        display: none;
    }

    .custom-file-input__icon {
        font-size: 80%;
        color: $blue;
    }
}

// Tables
.table th {
    font-weight: 600;
}

.td-fit {
    width: 1%;
    white-space: nowrap;
}

// Loader
.modal-body.c-loader-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 230px;
}
.c-loader {
        // .c-loader__progress 
        &__progress {
            .progress {
                border-radius: rem(8);
            }
        }
		// .c-loader__img
		&__img {
            display: block;
            max-width: 100%;
            width: 86px;
            height: 86px;
            // width: clamp(rem(80), 5.46875vw, rem(105));
            // height: clamp(rem(80), 5.46875vw, rem(105));
            margin: 0 auto;
            animation: spin 3.5s linear infinite;
		}
		// .c-loader__text
		&__text {
            text-align: center;
            font-size: 1rem;
            margin-top: rem(20);
		}
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}


.error-msg {
    color: $danger;
    font-size: rem(13);
    margin-top: rem(4);
    font-weight: 400;
    line-height: 1.1;
}

.c-success-icon {
    font-size: rem(90);
    color: #5EAAAA;

    .c-icon {
        display: block;
        margin-left: auto;
        margin-right: auto;
        line-height: 1;
    }
}