$primary: #128181;
$primary-light: #00A78C;
$red: #FA030D;
$danger: #E51B20;
$gray-600: #707070;
$secondary: #535353;
$warning: #F5AB05;
$blue: #0076F5;
$info: $blue;

$body-color: #070607;
$title-color: #474848;

$border-color: #B9B9B9;

$input-border-radius: 0;
$input-border-radius-sm: 0;
$input-border-radius-lg: 0;


$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1444px
);

$grid-gutter-width: 2rem;
// $container-padding-x: $grid-gutter-width;

$font-family-sans-serif: halyard-display, sans-serif;