@font-face {
    font-family: halyard-display;
    src: url("../../fonts/halyard-display/HalyardDisplayBook.woff2") format("woff2");
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: halyard-display;
    src: url("../../fonts/halyard-display/HalyardDisplay-Bold.woff2") format("woff2");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'icomoon';
    src:  url('../../fonts/icomoon/icomoon.eot?j06y5d');
    src:  url('../../fonts/icomoon/icomoon.eot?j06y5d#iefix') format('embedded-opentype'),
      url('../../fonts/icomoon/icomoon.ttf?j06y5d') format('truetype'),
      url('../../fonts/icomoon/icomoon.woff?j06y5d') format('woff'),
      url('../../fonts/icomoon/icomoon.svg?j06y5d#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  .c-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-ios-checkmark-circle-outline:before {
    content: "\e907";
  }
  .icon-email:before {
    content: "\e906";
  }
  .icon-card:before {
    content: "\e903";
  }
  .icon-coins:before {
    content: "\e904";
  }
  .icon-transfer:before {
    content: "\e905";
  }
  .icon-ios-checkmark-circle:before {
    content: "\e902";
  }
  .icon-search:before {
    content: "\e901";
  }
  .icon-account:before {
    content: "\e900";
  }
  .icon-fa-brands_facebook:before {
    content: "\e90a";
  }
  .icon-fa-brands_twitter:before {
    content: "\e90b";
  }
  .icon-fa-brands_youtube:before {
    content: "\e90c";
  }
  .icon-ios-arrow-down:before {
    content: "\e90d";
  }
  .icon-ios-call:before {
    content: "\e90e";
  }
  .icon-md-help:before {
    content: "\e90f";
  }  
  