.support-row {
    padding-bottom: clamp(rem(60), 6.25vw, rem(120));
    @media screen and (min-width: 992px) {
        justify-content: space-between;
        flex-wrap: nowrap;
        --bs-gutter-x: #{rem(44)};
    }

    .col {
        width: 100%;
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 1;

        @media screen and (min-width: 992px) {
            flex-grow: 0;
            flex-shrink: 1;
            &:first-child {
                flex-basis: 624px;
            }
            &:last-child {
                flex-basis: 684px;
                flex-shrink: 2;
            }
        }
        
    }
    
}

.support-group {
    border-bottom: 2px solid $border-color;
    padding-bottom: rem(32);
    margin-bottom: rem(32);

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

.faq-wrap {
    margin-bottom: 32px;
}

.faq-accordion {
    .accordion-item {
        border: none;
        margin-bottom: rem(24);
    }

    .accordion-header {
        font-size: inherit;
        font-weight: 300;
    }

    .accordion-button {
        padding: 0;
        border: none;
        font-size: inherit;
        font-weight: inherit;
        color: $primary;
        align-items: flex-start;
        &:after {
            display: none;
        }
        .accordion-button-icon {
            position: relative;
            display: block;
            width: rem(32);
            height: rem(32);
            background: $primary;
            border-radius: 100%;
            margin-right: rem(12);
            flex-grow: 0;
            flex-shrink: 0;

            @media screen and (min-width: 1200px) {
                width: rem(46);
                height: rem(46);
                margin-right: rem(20);
            }
    
            &:before, &:after {
                content: "";
                display: block;
                width: rem(16);
                height: 1px;
                background: $white;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(0);
                transition: all 0.15s ease-out;

                @media screen and (min-width: 1200px) {
                    width: rem(21);
                }
            }
        }
    
        &.collapsed {
            .accordion-button-icon::after {
                transform: translate(-50%, -50%) rotate(-90deg);
            }
        }
        &:not(.collapsed), &:focus {
            background-color: transparent;
            box-shadow: none;
        }

        &:not(.collapsed) {
            font-weight: 600;
        }


    }

    .accordion-body {
        padding-left: rem(66);
        padding-right: 0;
    }
    
}

.s-icon-link {
    text-decoration: none;
    color: var(--bs-body-color);
    display: inline-flex;
    align-items: center;
    gap: em(20, 22);
    // .s-icon-link__icon
    &__icon {
        color: #E73034;
        opacity: 0.48;
        font-size: 1.3em;
        transition: inherit;
    }
    // .s-icon-link__text
    &__text {
        color: var(--bs-body-color);
    }

    &:hover {
        .s-icon-link__icon {
            opacity: 0.6;
        }
    }
}

.s-videos-grid {
    display: grid;
    gap: rem(24);
    // grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    // grid-auto-columns: 1fr;
    
    
    @media screen and (min-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: 992px) {
        padding-top: rem(8);
    }

    @media screen and (min-width: 1200px) {
        gap: rem(32);
    }
}
.s-video {
    position: relative;
    overflow: hidden;
    &:first-child {
        
        
        @media screen and (min-width: 576px) {
            grid-column: 1 / 3;
        }
    }
		// .s-video__video
		&__video {
            position: relative;

            video {
                max-width: 100%;
                height: auto;
                display: block;
            }

            &:hover {
                .s-video__play {
                    opacity: 0.7;
                }
            }
		}
        // .s-video__overlay
        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 3;
        }
		// .s-video__play
		&__play {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -80%);
            color: $white;
            opacity: 0.34;
            font-size: rem(58);
            transition: all 0.3s ease-out;
            // @media (hover: none) {
            //     display: none;
            // }
		}
		// .s-video__info
		&__info {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            padding: rem(8) rem(16);
            background: $primary;
            color: $white;
            min-height: rem(58);
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 4;
            // @media (hover: none) {
            //     position: static;
            // }
		}
		// .s-video__title
		&__title {
            color: inherit;
            margin: 0;
            font-size: inherit;
            font-weight: 300;
		}
}

.support-form-container {
    background: #ecf5f5;
    padding-top: clamp(rem(16), 2.09vw, rem(40));
    padding-bottom: clamp(rem(16), 2.09vw, rem(40));
    padding-left: clamp(rem(16), 2.6vw, rem(50));
    padding-right: clamp(rem(16), 2.6vw, rem(50));
}