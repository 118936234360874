@use "sass:math";

@import "../../skote/scss/icons";
// @import "./node_modules/bootstrap/scss/functions";
@import "./public/vendor/libs/intl-tel-input-master/src/css/intlTelInput.scss";
@import "fonts";
@import "variables";
// @import "./node_modules/bootstrap/scss/variables";
// @import "./node_modules/bootstrap/scss/mixins";
// @import "./node_modules/bootstrap/scss/root";
@import "./node_modules/bootstrap/scss/bootstrap";
// @import "../../skote/scss/app";


// @import "../../skote/scss/bootstrap";

// @import "./node_modules/bootstrap/scss/reboot";
// @import "./node_modules/bootstrap/scss/containers";
// @import "./node_modules/bootstrap/scss/grid";

// @import "./node_modules/bootstrap/scss/utilities";

// // Helpers
// @import "./node_modules/bootstrap/scss/helpers";

// // Utilities
// @import "./node_modules/bootstrap/scss/utilities/api";


@import "mixins";
@import "common";

@import "auth/auth";

@import "parts/buttons";
// @import "../../skote/scss/custom/plugins/select2";
@import "parts/forms";
@import "parts/header";
@import "parts/footer";
@import "parts/hero";

@import "pages/home";
@import "pages/about";
@import "pages/blog";
@import "pages/page";
@import "pages/support";

@import "shop/shop";

.catalog-wrap {
    padding-bottom: 1rem;
}

.prod-quantity__group input.form-control {
    min-width: 80px;
}



.prod-applicator {
    .input-hidden {
        position: absolute;
        left: -9999px;
    }

    input[type=radio]:checked + label>img {
        border: 1px solid #fff;
        box-shadow: 0 0 5px 5px #128181;
    }

    /* Stuff after this is only to make things more pretty */
    input[type=radio] + label>img {
        border: 1px dashed #444;
        width: 150px;
        height: 150px;
        transition: 500ms all;
    }
}

.field-description {
    color: #595959;
    font-size: 0.8rem;
}

.applicator-select {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 250px;
    flex-wrap: wrap;
}

.applicator-img {
    display: block;
    position: relative;
    cursor: pointer;
    margin: 5px;
    overflow: hidden;
    border: 1px solid #dcdcdc;
    padding: 15px;
    max-width: 180px;
    img {
        display: block;
        position: relative;
        width: 150px;
        height: 150px;
        object-fit: cover;
        object-position: center;
    }
    .applicator-title {
        display: block;
        position: relative;
        text-align: center;
        height: 60px;
    }

    .applicator-price {
        text-align: center;
    }
}

#addNewHerdForm {
    .modal-footer {
        .btn {
            color: #1f1f1f;

            &:hover {
                color: #fff;
            }
        }
    }
}