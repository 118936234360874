.benefits-line {
    background: #f2f2f2;
    padding: rem(16) rem(24);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem(16);
    gap: clamp(rem(16), 4.6875vw, rem(90));
}
.benefit {
    display: flex;
    align-items: center;
    justify-content: center;
		// .benefit__icon
		&__icon {
            display: block;
            margin-right: rem(12);
            width: rem(25);
            flex-grow: 0;
            flex-shrink: 0;
		}
		// .benefit__text
		&__text {
            display: block;
            font-size: rem(18);
            color: #2d3135;
            opacity: 0.75;
            flex-grow: 1;
            flex-shrink: 1;
            font-weight: 400;
		}

        @media screen and (max-width: 576px) {
            display: none;
            &:first-child {
                display: flex;
            }
        }
}
.main-hero {
    position: relative;
    // @media screen and (min-width: 992px) {
    //     min-height: 634px;
    // }
		// .main-hero__banner
		&__banner {
            @media screen and (min-width: 992px) {
                position: absolute;
                top: 0;
                right: 0;
                max-width: 65%;
            }
		}
		// .main-hero__img
		&__img {
            display: block;
            max-width: 100%;
			// .main-hero__img_desktop
			&_desktop {
                display: none;
                @media screen and (min-width: 992px) {
                    display: block;
                    width: 100%;
                    height: 100%;
                    // margin-left: auto;
                }
			}
			// .main-hero__img_mob
			&_mob {
                margin-left: auto;
                margin-right: auto;
                @media screen and (min-width: 992px) {
                    display: none;
                }
			}
		}
        // .main-hero__container
        &__container {
            max-width: rem(1632);
            // max-width: rem(1504);
            margin-left: auto;
            margin-right: auto;
            @media screen and (min-width: 992px) {
                min-height: 634px;
                display: flex;
                flex-direction: column;
            //    justify-content: center;
                padding-top: rem(60);
            }
        }
		// .main-hero__content
		&__content {
            padding: rem(32) rem(24) rem(40);
            max-width: rem(680);
            margin-left: auto;
            margin-right: auto;
            @media screen and (min-width: 992px) {
                margin-left: 0;
                margin-right: 0;
            }
		}
		// .main-hero__text
		&__text {
            font-size: rem(22);
            line-height: 1.36;
            font-weight: 300;
            text-align: center;

            @media screen and (min-width: 992px) {
                text-align: left;
            }

            @media screen and (min-width: 1200px) {
                font-size: rem(34);
                line-height: 1.29;
            }
		}
		// .main-hero__cta
		&__cta {
            text-align: center;
            margin-top: rem(40);
            @media screen and (min-width: 992px) {
                text-align: left;
            }
		}
        // .main-hero__btn
        &__btn {
            display: inline-block;
            text-decoration: none;
            font-size: rem(18);
            line-height: 1.2;
            font-weight: 600;
            color: $white;
            background: #ff0e14;
            border: 1px solid #FF0E14;
            padding: rem(16) rem(44) rem(18);
            border-radius: rem(29);

            transition: all 0.3s ease-out;

            &:hover {
                color: $white;  
            }

            @media screen and (min-width: 360px) {
                min-width: 245px;
            }
        }
}
.categ-tile-section {
    padding-top: rem(20);
    padding-bottom: rem(15);

    

    @media screen and (min-width: 992px) {
        padding-bottom: rem(60);
        padding-top: rem(60);
    }
    
}
.categ-tile {
    display: grid;
    gap: rem(9);

    @media screen and (min-width: 768px) {
        gap: rem(65);
        gap: clamp(rem(32), 3.39vw, rem(65));
        grid-template-areas: 
        'first second'
        'first third';
        grid-auto-rows: 1fr;
        grid-auto-columns: 1fr;
    }
}
.categ-tile-group {
    display: flex;
    flex-direction: column;
    gap: rem(9);
    @media screen and (min-width: 768px) {
       flex-direction: row;
        gap: rem(30);
        &:first-child {
            grid-area: first;
        }
        &:nth-child(2) {
            grid-area: second;
        }

        &:nth-child(3) {
            grid-area: third;
        }
    }
}
.categ-tile-item {
    position: relative;

    
		// .categ-tile-item__link
		&__link {
            text-decoration: none;
            display: flex;
            height: rem(211);

            @media screen and (min-width: 768px) {
                flex-direction: column;
                height: auto;
            }

            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 60%;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 1.44%, #0A0A0A 113.93%);
            }
		}
		// .categ-tile-item__img
		&__img {
            display: block;
            
            flex-basis: 0;
            flex-grow: 1;
            flex-shrink: 1;
            max-width: 50%;
            height: 100%;
            object-fit: cover;

            &:only-of-type {
                max-width: 100%;
            }

            @media screen and (min-width: 768px) {
                max-width: 100%;
                height: auto;
            }
		}
		// .categ-tile-item__title
		&__title {
            text-decoration: none;
            font-size: rem(35);
            color: $white;
            font-weight: 600;
            text-align: center;
            padding: rem(34);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 2;
		}
}

// 
.ns-section {
    padding: rem(15) rem(8) rem(22);

    @media screen and (min-width: 768px) {
        background-color: $primary;
        padding-top: rem(80);
        padding-bottom: rem(80);
    }

    // .ns-section__img
    &__img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 535px;

        @media screen and (min-width: 768px) {
            width: auto;
            max-width: 100%;
            margin: 0;
        }
    }

    // .ns-section__content
    &__content {
        background-color: $primary;
        padding: rem(20) rem(16) rem(25);
        margin-top: -120px;
        margin-right: rem(16);
        margin-left: rem(16);

        @media screen and (min-width: 768px) {
            padding: 0;
            padding-left: 8px;
            margin: 0;
        }
    }

    // .ns-section__title
    &__title {
        font-size: rem(22);
        margin-bottom: em(21, 34);
        color: $white;
        font-weight: 600;

        @media screen and (min-width: 1200px) {
            font-size: rem(34);
            font-weight: 700;
        }
    }
    // .ns-section__text
    &__text {
        font-size: 1rem;
        line-height: 1.375;
        font-weight: 300;
        color: $white;
        max-width: rem(836);
        
        @media screen and (min-width: 1200px) {
            font-size: rem(22);
            line-height: 1.59;
        }
    }
    // .ns-section__buttons
    &__buttons {
        margin-top: rem(40);
        display: flex;
        flex-wrap: wrap;
        gap: rem(12);
        margin-right: -4px;
        margin-left: -4px;

        @media screen and (min-width: 768px) {
            margin-left: 0;
            margin-right: 0;
        }

        @media screen and (min-width: 1200px) {
            gap: rem(20);
        }
    }
}