.cart-cols {
	.card {
		margin-bottom: var(--bs-gutter-x);
		// &:last-child {
		// 	margin-bottom: 0;
		// }
	}
}
.cart-table {
    &.table > :not(caption) > * > * {
        padding: 1rem;
    }
		// .cart-table__prod-img
		&__prod-img {
            img {
                display: block;
                max-width: 86px;
            }
		}
		// .cart-table__prod-name
		&__prod-name {
			// word-break: normal;
			// overflow-wrap: normal;
		}
		// .cart-table__prod-price
		&__prod-price {
			font-weight: 400;
		}
		// .cart-table__prod-quantity
		&__prod-quantity {
		}
		// .cart-table__prod-subtotal
		&__prod-subtotal {
			font-weight: 400;
		}
		// .cart-table__prod-actions
		&__prod-actions {
			text-align: center;

			.btn {
				min-width: 32px;
			}
		}
}
.prod-quantity {
		// .prod-quantity__group
		&__group {
		}
}

.cart-table-footer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;

	.input-group {
		max-width: rem(420);
	}
}

.cart-total-price {
	font-weight: 600;
	font-size: rem(20);
}

@media screen and (min-width: 992px) and (max-width: 1079px), (max-width: 576px) {
	.cart-table {
		display: block;
		width: 100%;

		thead {
			display: none;
		}

		tbody {
			display: block;
		}

		.td-fit {
			width: auto;
		}

		tr {
			// display: flex;
			// flex-wrap: wrap;
			width: 100%;
			border-top: none;
			display: grid;
			gap: 0.5rem;
			padding-top: 1rem;
			padding-bottom: 1rem;
			grid-template-areas: 
			'img name name del'
			'img qty qty qty'
			'img subtotal subtotal subtotal '
			;

			&:last-child {
				border-bottom: none;
			}
		}

		.cart-table__prod-actions {
			grid-area: del;
			text-align: right;
		}

		.cart-table__prod-subtotal {
			grid-area: subtotal;
			// text-align: right;
		}

        .cart-table__variation {
            grid-area: price;
            align-self: center;
		}

		.cart-table__prod-quantity {
			grid-area: qty;
		}

		.cart-table__prod-price {
			grid-area: price;
			display: none;
		}

		.cart-table__prod-name {
			grid-area: name;
		}

		.cart-table__prod-img {
			grid-area: img;
			align-self: start;
		}

		td {
			border: none;
		}

		&.table > :not(caption) > * > * {
			padding: 0;
		}
	}


}

.cell-color-sm {
    display: block;
    position: relative;
    width: 25px;
    height: 25px;
    margin: auto;
  }