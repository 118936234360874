.btn-rounded {
    display: inline-block;
    text-decoration: none;
    border-width: 1px;
    border-style: solid;
    border-radius: rem(41);
    font-size: rem(20);
    line-height: 1.2;
    font-weight: 400;
    padding: rem(12) rem(20);
    display: flex;
    align-items: center;

    @media screen and (min-width: 1200px) {
        font-size: rem(24);
        padding: rem(16) rem(38);
    }
    .c-icon, .btn-rounded__icon {
        font-size: 1.2em;
        margin-right: 2px;

        transform: translateX(-4px);
    }
    // .btn-rounded_outline
    &_outline {

        &.btn-rounded_white {
            color: $white;
            background-color: transparent;
            border-color: $white;
        }

        &.btn-rounded_primary {
            color: $primary;
            background-color: transparent;
            border-color: $primary;
        }
    }
    // .btn-rounded_solid
    &_solid {

        &.btn-rounded_white {
            color: $primary;
            background-color: $white;
            border-color: $white;
        }

        &.btn-rounded_primary {
            color: $white;
            background-color: $primary;
            border-color: $primary;
        }
    }
}
