.site-footer {
    background: #707070;
    color: $white;
    a {
        text-decoration: none;
        transition: all 0.15s ease-out;
    }

    img {
        display: block;
        max-width: 100%;
    }
}
.site-footer-main {
    background-color: #2A2B2A;
    padding: rem(32) rem(30);
		// .site-footer-main__inner

		&__inner {
            max-width: rem(980);
            margin-left: auto;
            margin-right: auto;

            @media screen and (min-width: 576px) {
                display: grid;
                column-gap: rem(24);
                grid-auto-columns: 1fr;
                grid-template-areas:
                    'nav info logo'
                    'nav info aside'
                    'nav info aside'
                    'nav info aside'
                    'nav info aside';
            }
		}

		// .site-footer-main__logo

		&__logo {
            display: inline-block;
            max-width: rem(205);
            margin-bottom: rem(12);
            @media screen and (min-width: 576px) {
                grid-area: logo;
                margin-left: auto;
                align-self: start;
                margin-bottom: 0;
            }
		}

		// .site-footer-main__list

		&__list {
            list-style: none;
            padding: 0;
            margin: 0 0 rem(36);

            li {
                font-size: rem(15);
                // margin-bottom: rem(12);
                line-height: 1.9;
                display: flex;
                flex-wrap: wrap;
                column-gap: em(6, 18);

                @media screen and (min-width: 1024px) {
                    font-size: rem(18);
                }
            }

            a {
                color: inherit;
                &:hover {
                    text-decoration: underline;
                }
            }

            &_nav {
                @media screen and (min-width: 576px) {
                    grid-area: nav;
                }
            }

            &_info {
                @media screen and (min-width: 576px) {
                    grid-area: info;
                }
            }
		}

		// .site-footer-main__aside

		&__aside {
            display: flex;
            gap: rem(16);
            justify-content: space-between;
            align-items: center;

            @media screen and (min-width: 576px) {
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-start;
                // gap: rem(24);

                grid-area: aside;
                margin-left: auto;
                align-self: start;
            }
		}

		// .site-footer-main__award

		&__award {
            width: rem(140);
            flex-shrink: 2;

            @media screen and (min-width: 576px) {
                width: rem(170);
            }
		}
}
.site-footer-social {

		// .site-footer-social__link

		&__link {
            display: inline-block;
            color: $primary-light;
            font-size: rem(20);
            margin-left: 4px;

            &:hover {
                opacity: 0.8;
                color: $primary-light;
                text-decoration: none;
            }

            @media screen and (min-width: 576px) {
                font-size: rem(25);
                margin-right: 5px;
                margin-left: 0;
            }
		}
}
.site-footer-bottom {
    background-color: #707070;
    padding: rem(12) rem(16);
    font-size: rem(12);
    line-height: 1.67;

    a {
        color: inherit;
        &:hover {
            text-decoration: underline;
        }
    }

    @media screen and (min-width: 576px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-top: rem(22);
        padding-bottom: rem(22);

        gap: rem(8);
    }

    @media screen and (min-width: 1024px) {
        font-size: rem(14);
        line-height: 1.429;
    }
		// .site-footer-bottom__copy

		&__copy {
		}

		// .site-footer-bottom__links

		&__links {
            display: flex;
            flex-wrap: wrap;
            column-gap: rem(8);
		}
}
