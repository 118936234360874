.checkout-wrap {
    padding-top: rem(64);
    padding-bottom: rem(88);
    padding-left: rem(38);
    padding-right: rem(38);
    // 1420 + 38 * 2 = 1420
    max-width: rem(1496);
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 360px) {
        padding-right: rem(24);
        padding-left: rem(24);
    }
}

.order-table {
    padding: rem(8);
    // .order-table__row
    &__row {
        display: flex;
        gap: rem(12);
        align-items: center;
    }
    // .order-table__prod-img
    &__prod-img {
        max-width: 20%;
        flex-basis: rem(86);
        flex-grow: 0;
        flex-shrink: 0;
        img {
            display: block;
            max-width: 100%;
        }

        @media screen and (min-width: 576px) {
            max-width: 29%;
        }
    }
    // .order-table__prod-info
    &__prod-info {
        display: flex;
        gap: rem(8);
        align-items: flex-start;
        justify-content: space-between;
        flex-grow: 1;
        flex-shrink: 1;
    }
    // .order-table__prod-name
    &__prod-name {
        font-weight: 300;
        flex-grow: 1;
        max-width: rem(130);
    }
    // .order-table__prod-price
    &__prod-price {
        font-weight: 400;
        flex-grow: 0;
        text-align: right;
        // margin-left: auto;
        flex-basis: rem(64);
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.pi-tabs-wrap {
    max-width: rem(800);
}

.pi-tabs-nav {
    align-items: stretch;
    .nav-item {
        flex-grow: 1;

        &:not(:last-child) {
            .nav-link {
                border-right: none;
            }
        }

        @media screen and (min-width: 768px) {
            flex-basis: 0;
        }
    }

    .nav-link {
        border: 1px solid $border-color;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: rem(12);
        font-size: 1rem;
        color: var(--bs-body-color);
        height: 100%;

        &.active {
            background-color: $primary;
            border-color: $primary;
            color: $white;
        }

        &.disabled {
            opacity: 0.55;
        }

        // .nav-link__icon
        // &__icon {
        //     font-size: rem(24);
        // }
    }


}

.pi-tabs-content {
    .tab-pane {
        padding-left: 1px;
    }

    // padding-right: 1px;
}

.pi-tabs-form {
    max-width: rem(470);
}

.delivery-addresses-select {
    li {
        list-style: none;
        &.active {
            color: $primary;
            font-weight: bold;
        }
    }
}

.card-holder {
    li {
        margin-bottom: 0.5rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .form-check {
        display: flex;
        gap: 10px;
        align-items: center;
        line-height: 1;
        margin: 0;
        padding: 0;

        .form-check-input {
            float: none;
            margin: 0;
        }
    }

    .payment-method-icon-sm-wrap {
        width: 32px;
        flex-shrink: 0;
        flex-grow: 0;
    }

    .payment-method-icon-sm {
        display: block;
        max-width: 100%;
    }
}