.filters {
    // .filters__header
    &__header {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $border-color;
        @media screen and (min-width: 992px) {
            display: none;
        }
    }
    // .filters__title {
    &__title {
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 0;
        line-height: 1.5;
        font-size: 1.25rem;
    }
    // .filters__body
    &__body {
        @media screen and (max-width: 991.98px) {
            overflow-y: auto;
            padding-top: 1rem;
            padding-bottom: 1rem;
            .filters-group__title, .filters-group__body {
                padding-left: 1rem;
                padding-right: 1rem;
            }

            .filters-group__title {
                padding-bottom: rem(12);
            }

            .filters-group__body {
                padding-top: rem(12);
            }
        }
    }
    // .filters__footer
    &__footer {
        padding: 1rem;
        border-top: 1px solid $border-color;

        .btn {
            width: 100%;
        }

        @media screen and (min-width: 992px) {
            display: none;
        }
    }

    @media screen and (max-width: 991.98px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        background: $white;
        display: flex;
        flex-direction: column;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-out;

        &.active {
            opacity: 1;
            visibility: visible;
        }
    }

    @media screen and (min-width: 992px) {
        margin-top: rem(-30);
    }
}
.filters-group {
    margin-bottom: rem(24);
    &:last-child {
        margin-bottom: 0;
    }

    .form-check {
        margin-bottom: rem(8);
    }

    // .filters-group__title
    &__title {
        font-size: rem(18);
        font-weight: 400;
        padding-bottom: rem(4);
        border-bottom: 1px solid $border-color;
    }
    // .filters-group__body
    &__body {
        padding-top: rem(8);
    }
}

.filtes-modal-open {
    @media screen and (max-width: 991.98px) {
        overflow: hidden;
    } 
}

// modal-open
// overflow: hidden; padding-right: 17px;
