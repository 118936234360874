.site-hero-sm {
    min-height: rem(350);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgba(#050505, 0.49);
        z-index: 1;
    }
    // .site-hero-sm__content
    &__content {
        position: relative;
        color: $white;
        z-index: 2;
    }
    // .site-hero-sm__subtitle
    &__subtitle {
        font-weight: 600;
        font-size: rem(20);
        text-decoration: underline;
        margin-bottom: rem(12);
        font-size: clamp(rem(20), calc(2vw + 0px), rem(25));

        // @media screen and (min-width: 1200px) {
        //     font-size: rem(25);
        // }
    }
    // .site-hero-sm__title
    &__title {
        font-weight: 300;
        font-size: rem(42);
        font-size: clamp(rem(40), calc(3.5vw + 0px), rem(50));
        line-height: 1;
        // @media screen and (min-width: 1200px) {
        //     font-size: rem(50);
        // }
    }
}

.s-text-wrap {
    padding-top: rem(60);
    padding-bottom: rem(60);
    ul, ol {
        padding-left: rem(26);
    }

    ol ol, ol.list-style-lower-latin {
        list-style-type: lower-latin;
    }

    table {
        tbody {
            vertical-align: top;

            th {
                padding-right: 1rem;
            }
        }
    }
}

.s-subtitle-underline {
    display: inline-block;
    position: relative;
    font-size: rem(25);
    font-size: clamp(rem(20), calc(2vw + 0px), rem(25));
    font-weight: 600;
    margin-bottom: rem(16);
    margin-top: rem(40);
    padding-bottom: 12px;

    @media screen and (min-width: 1024px) {
        
    }

    &:first-child {
        margin-top: 0;
    }

    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: $primary;
    }
}

