.s-info-section {
    padding-top: rem(60);
    padding-bottom: rem(60);

    @media screen and (min-width: 1200px) {
        padding-top: rem(88);
        padding-bottom: rem(88);
    }
}
.s-info-block {
    margin-bottom: rem(45);
    @media screen and (min-width: 992px) {
        display: flex;
    }
    @media screen and (min-width: 1200px) {
        margin-bottom: rem(70);
    }

    &:last-child {
        margin-bottom: 0;
    }
		// .s-info-block_reverse
		&_reverse {
            @media screen and (min-width: 992px) {
                .s-info-block__body {
                    padding-right: 0;
                    padding-left: rem(44);
                    order: 2
                }
                .s-info-block__img {
                    order: 1;
                }
            }
		}
		// .s-info-block__body
		&__body {
            max-width: rem(668);
            @media screen and (min-width: 992px) {
                width: 50%;
                max-width: unset;
                padding-right: rem(44);
            }
		}
		// .s-info-block__img
		&__img {
            display: block;
            max-width: 100%;
            margin-top: rem(30);
            @media screen and (min-width: 992px) {
                width: 50%;
                max-width: 50%;
                margin-top: 0;
            }
		}
}
.s-title {
    color: $primary;
    font-size: rem(30);
    line-height: 1.5;
    font-weight: 400;
    margin-bottom: 0.5em;
}
.s-text {
    > * {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.s-text + .s-title {
    margin-top: rem(40);
    @media screen and (min-width: 1200px) {
        margin-top: rem(60);
    }
}


.partnership-block {
    background-color: #f7f4ef;
    padding: rem(30);
    margin-bottom: rem(60);
    @media screen and (min-width: 576px) {
        padding: rem(40);
    }
    @media screen and (min-width: 768px) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: rem(40);
    }
    @media screen and (min-width: 1200px) {
        padding: rem(48) rem(84);
        // margin-bottom: rem(60);
    }
    
		// .partnership-block__content
		&__content {
            @media screen and (min-width: 768px) {
                max-width: rem(635);
            }
            @media screen and (min-width: 992px) {
                margin-top: rem(30);
            }
		}
		// .partnership-block__img
		&__img {
            display: block;
            max-width: 100%;
            margin-top: rem(30);

            @media screen and (min-width: 768px) {
                margin-top: 0;
                flex-grow: 0;
                flex-shrink: 1;
                max-width: 40%;
            }
		}
}


