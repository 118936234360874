@use "sass:math";

@function stripUnit($value) {
    @return math.div($value, ($value * 0 + 1));
}

$base_fz: 18;

@function rem($pxValue) {
    @return #{math.div(stripUnit($pxValue), stripUnit($base_fz))}rem;
}

@function em($pxValue, $fzValue) {
    @return #{math.div(stripUnit($pxValue), stripUnit($fzValue))}em;
}

@each $color,
$value in $theme-colors {
    .bg-#{$color} {
        &.bg-soft{
            background-color: rgba(($value), 0.25) !important;

        }
    }
}

@mixin button-soft-variant(
  $color,
  $color-hover: color-contrast($color),
  $active-background: $color,
  $active-border: $color,
  $active-color: color-contrast($active-background)
) {
  color: $color;
  background-color: rgba(($color), 0.15);
  border-color: transparent;

  &:hover {
    color: $color-hover;
    background-color: $active-background;
    border-color: transparent;
  }

  .btn-check:focus + &,
  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: $active-color;
    background-color: $active-background;
    border-color: transparent;

    &:focus {
      @if $enable-shadows {
        @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
      }
    }
  }

  &:disabled,
  &.disabled {
    color: $color;
    background-color: transparent;
  }
}

@each $color, $value in $theme-colors {
    .btn-soft-#{$color} {
      @include button-soft-variant($value);
    }
}