.site-hero {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: rem(690);
    padding-top: rem(30);
    padding-bottom: rem(30);
    display: flex;
    flex-direction: column;
    justify-content: center;
    // .site-hero__content
    &__content {
        width: 100%;
        max-width: rem(474);
        padding: rem(60) rem(45);
        background: rgba($primary, 0.75);
        color: $white;
    }
    // .site-hero__subtitle
    &__subtitle {
        font-weight: 600;
        font-size: rem(30);
        text-decoration: underline;
        margin-bottom: rem(12);

        @media screen and (min-width: 1200px) {
            font-size: rem(35);
        }
    }
    // .site-hero__title
    &__title {
        font-weight: 300;
        font-size: rem(42);
        line-height: 1;
        @media screen and (min-width: 1200px) {
            font-size: rem(50);
        }
    }
}

.mini-hero {
    padding-top: rem(60);
    padding-bottom: rem(40);
    @media screen and (min-width: 992px) {
        padding-bottom: rem(60);
    }
    // .mini-hero__subtitle
    &__subtitle {
        font-weight: 600;
        font-size: rem(30);
        text-decoration: underline;
        margin-bottom: rem(12);

        @media screen and (min-width: 1200px) {
            font-size: rem(35);
        }
    }
    // .mini-hero__title
    &__title {
        font-weight: 300;
        font-size: rem(42);
        line-height: 1;
        @media screen and (min-width: 1200px) {
            font-size: rem(50);
        }
    }
}
