.fw-section {
    padding: rem(60) rem(16);
}

.fw-section + .fw-section {
    padding-top: rem(20);
}

.fw-slider {
    position: relative;
    .owl-next, .owl-prev {
        // font-size: rem(40);
        z-index: 5;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: rem(40);
        height: rem(40);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        opacity: 0.28;
        

        span {
            font-size: 1px;
            opacity: 0;
        }
    }

    .owl-next {
        right: 0;
        background-image: url('../../images/front/icons/ios-arrow-forward.svg')!important;
    }

    .owl-prev {
        left: 0;
        background-image: url('../../images/front/icons/ios-arrow-back.svg')!important;
    }
}
.fw-slider-more {
    text-align: right;
    margin-right: rem(24);
    margin-top: rem(16);
    @media screen and (min-width: 1600px) {
        margin-right: rem(62);
    }
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}

.fw-slide {
}
.ap-card {
    display: block;
		// .ap-card__img
		&__img {
            display: block;
            max-width: 100%;
		}
}