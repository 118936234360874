.prod-card {
    background: $white;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-width: rem(370);
    margin-left: auto;
    margin-right: auto;
    &:hover {
        .prod-card__img_primary {
            opacity: 0;
        }
        .prod-card__img_hover {
            opacity: 1;
        }
    }
    // .prod-card__avatar-wrap
    &__avatar-wrap {
        padding: rem(16) rem(16) 0;
        
    }
    // .prod-card__avatar
    &__avatar {
        display: block;
        position: relative;
        height: 0;
        padding-top: 100%;
        box-shadow: 0 2px 7px rgba(0,0,0,0.19);
    }
    // .prod-card__img
    &__img {
        display: block;
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: all 0.35s ease-out;
        // .prod-card__img_primary
        &_primary {
            z-index: 2;
        }
        // .prod-card__img_hover
        &_hover {
            z-index: 1;
            opacity: 0;
        }
    }
    // .prod-card__body
    &__body {
        padding: rem(16);
    }
    // .prod-card__title
    &__title {
        font-size: rem(22);
        margin-bottom: rem(4);
        color: #050505;
        font-weight: 400;
        text-align: center;

        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                color: $primary;
            }
        }
    }
    // .prod-card__price
    &__price {
        font-size: rem(22);
        color: #050505;
        font-weight: 400;
        text-align: center;
        span {
            color: $primary;
            font-weight: 600;
        }
    }
    // .prod-card__footer
    &__footer {
        text-align: center;
        border-top: 1px solid $border-color;
        padding: rem(12) rem(16) rem(16);
        margin-top: auto;
    }
    // .prod-card__btn
    &__btn {

    }
}
